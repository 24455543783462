import { RefObject, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FinancialRow, LoadingStatus } from 'types/financials.types';
import { Transaction } from 'types/statutory.types';
import { notifyError } from 'utils/notifications.utils';
import { createTransactions, getTransactionQuery } from 'utils/financials.utils';
import statutoryService from 'services/statutory.service';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { financialsSlice } from 'store/financials.slice';
import { IRowNode } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import useAbort from 'hooks/useAbort';

const useLazyLoadingRow = (gridRef: RefObject<AgGridReact>, templateId: number) => {
  const period = useAppSelector(state => state.financials.tables[ templateId ].period);
  const { controller, signal } = useAbort();
  const [ t ] = useTranslation('financials');
  const dispatch = useAppDispatch();

  const changeLoadingStatus = useCallback((status: LoadingStatus, nodeId: string) => {
    dispatch(financialsSlice
      .actions
      .setLazyLoadingStatus({
        nodeId,
        status,
        templateId
      }));
  }, [ templateId ]);

  const addTransactionsToNode = useCallback((
    nodeToAppend: IRowNode,
    childrenToExpand: string[] = []
  ) => {
    if (!nodeToAppend.data.hasTransactions) return;

    changeLoadingStatus(LoadingStatus.LOADING, nodeToAppend.id);
    const request = statutoryService.getTransactionLines(
      {
        ...getTransactionQuery([ nodeToAppend ], templateId, period),
      },
      signal,
    );
    request
      .then((res) => {
        const transactions: Transaction[] = res.data;
        if (transactions.length === 0) {
          // Don't update the table if for some reason we got nothing
          return changeLoadingStatus(LoadingStatus.LOADED, nodeToAppend.id);
        }
        const newGroupData = createTransactions(transactions, nodeToAppend);
        gridRef?.current?.api?.applyTransactionAsync(
          { add: mapTransactions(newGroupData) },
          () => {
            gridRef?.current?.api?.redrawRows({ rowNodes: [ nodeToAppend ] });

            nodeToAppend.childrenAfterGroup.forEach(child => {
              if (childrenToExpand.includes(child.key)) {
                child.setExpanded(true);
              }
            });
          }
        );

        nodeToAppend.setExpanded(true);
        gridRef?.current?.api?.applyTransaction({
          update: [ { ...nodeToAppend.data, asyncStatus: 'loaded' } ]
        });
        changeLoadingStatus(LoadingStatus.LOADED, nodeToAppend.id);
      })
      .catch((e) => {
        changeLoadingStatus(LoadingStatus.TO_LOAD, nodeToAppend.id);
        gridRef?.current?.api?.applyTransaction({
          update: [ { ...nodeToAppend.data, asyncStatus: 'not_loaded' } ]
        });

        if (e.message !== 'canceled') {
          notifyError(t('notifications.unexpected-error.message', { ns: 'common' }));
          throw e;
        }
      });
  }, [ changeLoadingStatus, period ]);

  const createFilePath = useMemo(
    () => (
      transaction,
      defaultPath,
      groupByProxy = false,
    ) => {
      const unassignedPath = [ ...defaultPath ];

      if (groupByProxy) {
        const path = unassignedPath;
        path.splice(unassignedPath.length - 1, 0, transaction.rowData.productProxy);

        return path;
      }
      return [ ...unassignedPath ];
    },
    []
  );

  const mapTransactions = useCallback(
    (transactions: FinancialRow[]) => {
      const counts = {};

      transactions
        .filter((el) => el.rowData?.productProxy)
        .forEach((tr) => {
          counts[ tr.rowData?.productProxy ] = counts[ tr.rowData?.productProxy ]
            ? counts[ tr.rowData?.productProxy ] + 1
            : 1;
        });

      return [
        ...transactions.map((transaction) => {
          const groupByProxy = counts[ transaction.rowData.productProxy ] > 1;
          return {
            ...transaction,
            filePath: createFilePath(transaction, transaction.filePath, groupByProxy),
          };
        }),
      ];
    },
    []
  );

  return {
    addTransactionsToNode,
    controller,
  };
};

export default useLazyLoadingRow;
