import React, { useState } from 'react';
import GhostIconButton from 'components/elements/button/ghostIcon/GhostIconButton';
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg';
import useReportQuery from '../../financialTable/hooks/useReportQuery';
import { useFinancialTable } from '../../../../context/FinancialTableContext';
import { useAppSelector } from '../../../../store/hooks/hooks';
import { selectPeriod } from '../../../../store/financials.slice';
import { useTranslation } from 'react-i18next';
import WarnBeforeExitModal from 'components/elements/modals/warnings/WarnBeforeExitModal';

const RefreshTableButton = () => {

  const anyTableHaveNewCells = useAppSelector((state) => {
    return Object.values(state.financials?.tables)?.some((table) => table?.hasNewCells);
  });

  const [ wantToRefresh, setWantToRefresh ] = useState(false);
  
  const { state: { templateId } } = useFinancialTable();
  const period = useAppSelector(selectPeriod(templateId));
  const { t } = useTranslation('financials');

  const { refetch } = useReportQuery({ templateId,
    period,
    shouldFetch: true
  });

  const onRefresh = () => {
    if (anyTableHaveNewCells) {
      setWantToRefresh(anyTableHaveNewCells);
    } else {
      refetch();
    }
  };

  const onRefreshConfirmed = () => {
    setWantToRefresh(false);
    refetch();
  };

  return <>

    <GhostIconButton
      aria-label='refresh'
      onClick={ onRefresh }
      tooltip={ t('actions.refresh') }
    >
      <RefreshIcon />
    </GhostIconButton>

    <WarnBeforeExitModal
      isVisible={ wantToRefresh }
      onClose={ () => setWantToRefresh(false) }
      onConfirm={ onRefreshConfirmed }
    />
  </>;
};

export default RefreshTableButton;
