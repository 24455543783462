import dayjs, { Dayjs } from 'dayjs';
import { getDisplayName } from 'utils/common.utils';
import { IRowNode } from 'ag-grid-community';
import { RowType } from '../types/financials.types';
import { DisplayName } from '../types/app.types';
import { isProductProxy } from './financials.utils';

const getTransactionDates = (node: IRowNode, isProxy: boolean): string[] => {
  if (isProxy) {
    const children = node.allLeafChildren.filter(child => child.id !== node.id);
    return children.map(child => child.data.rowData.transactionDate);
  }

  return [ node.data.rowData.transactionDate ];
};

const getData = (node: IRowNode) => {
  return {
    ...node.data.actual ? node.data.actual : {},
    ...node.data.plan ? node.data.plan : {}
  };
};

const getOldestDate = (node: IRowNode): Dayjs => {
  const dateKeys = [];
  const isProxy = isProductProxy(node);
  if (isProxy || node.data.type === RowType.TRANSACTION) {
    dateKeys.push(...getTransactionDates(node, isProxy));
  } else {
    const data = getData(node);
    const keys = Object.keys(data).filter(key => dayjs(key).isValid() && isNaN(Number(key)));
    dateKeys.push(...keys);
  }
  if (!dateKeys.length) return null;

  const oldestDate = dateKeys.sort((a, b) => dayjs(a).diff(dayjs(b), 'day'))[ 0 ];
  return dayjs(oldestDate);
};
export const compareByDate = (secondaryComparator?: NodeComparator): NodeComparator => {
  return (a: IRowNode, b: IRowNode) => {
    const dateA = getOldestDate(a);
    const dateB = getOldestDate(b);

    const compareValue = dayjs(dateA).diff(dateB, 'day');
    if (compareValue === 0 && secondaryComparator) {
      return secondaryComparator(a, b);
    }
    return compareValue;
  };
};

const getTotalAmount = (node: IRowNode): number => {
  const actual = node?.data?.actual?.total;
  const budget = node?.data?.budget?.total;
  if (actual && budget) {
    return actual + budget;
  }
  return actual || budget;
};

export const compareByAmount = (secondaryComparator?: NodeComparator): NodeComparator => {
  return (a: IRowNode, b: IRowNode) => {
    const getValueFromProductProxy = (array) => {
      return Object.values(array).filter(el => el).reduce((partialSum: number, x: number | null) =>
        x !== null ? partialSum + x : partialSum, 0);
    };

    const amountA = a?.data?.rowData?.amount ||
      getTotalAmount(a) ||
      (a?.aggData ? getValueFromProductProxy(a?.aggData) : null);

    const amountB = b?.data?.rowData?.amount ||
      getTotalAmount(b) ||
      (b?.aggData ? getValueFromProductProxy(b?.aggData) : null);

    const compareValue = amountA - amountB;
    if (compareValue === 0 && secondaryComparator) {
      return secondaryComparator(a, b);
    }
    return compareValue;
  };
};

type NodeComparator = (a: IRowNode, b: IRowNode) => number;

export const compareByDisplayName = (secondaryComparator?: NodeComparator): NodeComparator => {
  return (a: IRowNode, b: IRowNode) => {
    const nameA = (a?.data?.rowData?.name && getDisplayName(a?.data?.rowData.name)) || a?.key;
    const nameB = (b?.data?.rowData?.name && getDisplayName(b?.data?.rowData.name)) || b?.key;
    const compareValue = nameA.localeCompare(nameB);
    if (compareValue === 0 && secondaryComparator) {
      return secondaryComparator(a, b);
    }
    return compareValue;
  };
};

export enum SortingType {
  ALPHABETICAL,
  DATE,
  AMOUNT
}

export type SortingSettings = {
  type: SortingType;
  isDescending: boolean;
};

export type NodeSortingSetting = {
  primary?: SortingSettings;
  secondary?: SortingSettings;
};

const NODE_COMPARATORS = {
  [ SortingType.DATE ]: compareByDate,
  [ SortingType.AMOUNT ]: compareByAmount,
  [ SortingType.ALPHABETICAL ]: compareByDisplayName,
};
export const getComparator = (
  settings: SortingSettings, secondary?: NodeComparator
): NodeComparator => {
  const multiplier = settings?.isDescending ? -1 : 1;
  const comparator = NODE_COMPARATORS[ settings?.type ] || compareByDate;
  return (a, b) => {
    if (a?.key === 'Unassigned' || b?.key === 'Unassigned')
      return;

    if (a?.data?.type === RowType.NEW_BUDGET_ITEM || b?.data?.type === RowType.NEW_BUDGET_ITEM)
      return 1;

    return comparator(secondary)(a, b) * multiplier;
  };
};

export const displayNameComparator = (a: DisplayName, b: DisplayName) => {
  const aName = getDisplayName(a);
  const bName = getDisplayName(b);
  if (!aName || !bName) return 0;

  return aName.localeCompare(bName);
};