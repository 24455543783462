import React, {
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import styles from './Editors.module.scss';
import useKeyPressListener from 'hooks/useKeyPressListener';
import SearchableSelect from 'components/elements/searchableSelect/SearchableSelect';
import { Account } from '../../../../../types/statutory.types';

const OPEN_KEY_BINDING = { code: 'Enter' };

type AccountSelectValue = { primary: number };

interface SearchableSelectProps {
  value: AccountSelectValue;
  field: string;
  icon?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  setValue: (value: AccountSelectValue) => void;
  formatValue?: (value: Value | string) => string;
}

type Value = {
  id: number | string;
  name: number | string;
};
interface ValuesProps {
  values: Account[];
}

const AccountCellEditor = (props: SearchableSelectProps & ValuesProps,) => {

  const value = props.value;
  const column = props.field;
  const [ open, setOpen ] = useState(false);

  const options = useMemo(() => {
    return props.values.map((_value: Account) => ({
      value: _value.id,
      label: props.formatValue ? props.formatValue(_value) : typeof _value === 'object' ?
        _value?.name : _value,
    }));
  }, [ props.values, props.formatValue ]);

  const updateValue = useCallback((val) => {
    props.setValue({ primary: val });
  }, [ column ]);

  const onChange = useCallback((key: number) => {
    updateValue(key);
  }, [ props.values, updateValue ]);

  const openOnEnter = useCallback(() => setOpen(true), []);

  useKeyPressListener({ keyBinding: OPEN_KEY_BINDING, cb: openOnEnter, enabled: open });

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  return (
    <SearchableSelect
      open={ open }
      disabled={ props.disabled }
      placeholder={ props.placeholder }
      getPopupContainer={ (triggerNode) => triggerNode }
      onDropdownVisibleChange={ onOpenChange }
      dropdownMatchSelectWidth={ 450 }
      value={ value?.primary }
      options={ options }
      popupClassName='ag-custom-component-popup'
      className={ styles.searchableSelect }
      onChange={ onChange }
    />
  );
};

AccountCellEditor.displayName = 'AccountCellEditor';
export default AccountCellEditor;
