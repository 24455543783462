import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './Editors.module.scss';
import { Input, InputRef, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

interface Props {
  value: string;
  field: string;
  headerName: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
}

interface ValidationProps {
  maxLength: number;
}

const TextCellEditor = (props: Props & ValidationProps) => {
  const [ t ] = useTranslation('common');
  const [ value, setValue ] = useState(props.value);
  const refInput = useRef<InputRef>(null);
  const field = props.headerName;

  useEffect(() => {
    refInput.current?.focus();
  }, []);

  useEffect(() => {
    props.setValue(getValueOrLimit(value));
  }, [ value ]);

  useEffect(() => {
    setValue(props.value);
  }, [ props.value ]);

  const getValueOrLimit = useCallback((val: string) => {
    return val?.toString().slice(0, (props.maxLength || val?.toString().length) ?? 10000) ?? '';
  }, []);

  const valid = useMemo(() => {
    if (!props.maxLength) {
      return true;
    }
    if (!value) {
      return true;
    }
    return value.length <= props.maxLength;
  }, [ props, value ]);

  return (
    <Tooltip
      open={ !valid }
      title={ t('notifications.maxLength.message', { field, length: props.maxLength }) }
    >
      <Input
        ref={ refInput }
        value={ value }
        name={ props.field }
        disabled={ props.disabled }
        placeholder={ props.placeholder }
        onChange={ (e) => setValue(e.target.value) }
        status={ valid ? null : 'error' }
        className={ styles.textInput }
      />
    </Tooltip>
  );
};

TextCellEditor.displayName = 'TextCellEditor';
export default TextCellEditor;
