import React, { useEffect, useMemo, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import useCommandBarEffect from 'components/commandBar/hooks/useCommandBarEffect';
import { CommandBarUtil } from 'components/commandBar/types/commandBar.types';
import { CellRange, IRowNode } from 'ag-grid-community';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import useDetailedViewModal from 'components/financials/financialTable/hooks/useDetailedViewModal';
import { defaults } from 'components/commandBar/context/CommandBarContext';
import { financialsSlice, updateDetailedViewSettings } from 'store/financials.slice';
import { DetailedViewType, RowType } from 'types/financials.types';
import { sectionKeys } from 'components/financials/detailedView/utils/detailedView.utils';
import { ReportType } from 'types/templates.types';
import { getListOfSelectedRows, getValueCellsInRange } from '../../../utils/selection.utils';
import { NO_ADD_BUTTON_ROW_TYPES, budgetItemsFromSelection }
  from 'components/financials/utils/addRow.utils';
import { isProductProxy } from 'utils/financials.utils';

const KEY_BINDINGS = {
  detailedView: { primary: { key: 'Enter' } },
  addRow: {
    primary: { key: '+' },
    alternative: { code: 'Equal', withShift: true }
  },
  removeSelected: {
    primary: { key: 'Delete' },
    alternative: { key: 'Backspace' }
  },
  overviewDetails: { primary: { key: 'Enter', withShift: true } }
};

interface Props {
  gridReady: boolean;
  templateId: number;
  tableIsInEdit: boolean;
  gridRef: React.MutableRefObject<AgGridReact>;
  addRow: (row: IRowNode | undefined) => void;
  cellRange: CellRange[];
  selectedNodes: IRowNode[];
  setRowsForRemove: (rows: IRowNode[], columns?: string[]) => void;
}

const detailedViewUtil = defaults.find(util => util.key === 'detailed-view');
const addRowButton = defaults.find((button) => button.key === 'addRow');
const removeSelectedButton = defaults.find((button) => button.key === 'removeSelected');
const overviewDetailsButton = defaults.find((button) => button.key === 'overviewDetails');

const useCellCommandBar = ({
  gridReady,
  templateId,
  tableIsInEdit,
  gridRef,
  cellRange,
  selectedNodes,
  addRow,
  setRowsForRemove
}: Props) => {
  const anyModalOpened = useAppSelector(state => state.app.anyModalOpened);

  const isTableActive = useAppSelector(state => {
    const active = state.financials.active;
    return active?.templateId === templateId && active?.type === 'table';
  });
  const appDispatch = useAppDispatch();
  const [ utilsState, setUtilsState ] = useState<CommandBarUtil[]>([]);

  const canDeleteSelected = (
    newCellRange: CellRange[] | null,
    newSelectedNodes: IRowNode[] = []
  ) => {
    if (!newCellRange && !newSelectedNodes?.length) {
      return false;
    }

    if (newCellRange?.length) {
      const areNewRowsInRange = 
        getListOfSelectedRows(newCellRange, gridRef.current)
          .filter(filterNotDisplayed)
          .every((row) => row?.data?.type === RowType.NEW_BUDGET_ITEM);

      return areNewRowsInRange;
    }

    if (newSelectedNodes?.length) {
      return newSelectedNodes.every((node) => {
        return (node.childrenAfterFilter.length > 0 && node.childrenAfterFilter.at(0).displayed) ||
          node.data?.type === RowType.NEW_BUDGET_ITEM; 
      });
    }

    return false;
  };

  const canAddRow = (newCellRange: CellRange[], newSelectedNodes: IRowNode[] = []) => {
    if (
      !gridRef.current.columnApi.getColumns()
        .some(column => column.getColId().startsWith(ReportType.PLAN))
    ) {
      return false;
    }

    if (newCellRange?.length && newCellRange.at(-1)?.endRow) {
      const lastSelectedRow = gridRef.current.api
        .getDisplayedRowAtIndex(newCellRange.at(-1)?.endRow.rowIndex);
      return !NO_ADD_BUTTON_ROW_TYPES.includes(lastSelectedRow?.data?.type);
    } else if (newSelectedNodes?.length) {
      return !NO_ADD_BUTTON_ROW_TYPES.includes(newSelectedNodes.at(-1)?.data?.type);
    }
  };

  const { setDetailedViewData } = useDetailedViewModal({ templateId, gridRef });
  const overviewDropdown = useAppSelector(state => state.financials.overviewDropdown);

  const isKeyboardDisabled =
    anyModalOpened ||
    tableIsInEdit ||
    !!overviewDropdown;

  const filterNotDisplayed = (node: IRowNode) => {
    if (!node) {
      return false;
    }
    if (isProductProxy(node.parent) && node.parent.displayed && node.parent.isSelected()) {
      return true;
    }
    return node.displayed;
  };

  const tableElement = useMemo(() => 
    document.querySelector(`[data-view-item-id="item__table__${ templateId }"]`) as HTMLElement,
  [ templateId, gridReady ]);

  const hydrateButtons = (newCellRange: CellRange[] | null, newSelectedNodes: IRowNode[] = []) => {
    const rowsInRange = newCellRange ?
      getListOfSelectedRows(newCellRange, gridRef.current).filter(filterNotDisplayed) :
      [];

    const visibleNodes = newSelectedNodes?.filter(filterNotDisplayed) ?? [];

    const areNewRowsInRange = rowsInRange.length ? rowsInRange
      .every((row) => row?.data?.type === RowType.NEW_BUDGET_ITEM) : false;
    const newCellsInRange = areNewRowsInRange ?
      getValueCellsInRange(newCellRange, gridRef.current) :
      [];

    setUtilsState([
      {
        key: 'detailed-view',
        type: 'button',
        icon: detailedViewUtil.icon,
        hidden: false,
        keyBinding: {
          ...KEY_BINDINGS.detailedView,
          disabled: isKeyboardDisabled,
          keyBindingNode: { current: tableElement },
        },
        disabled: !visibleNodes.length && !rowsInRange.length,
        placement: 'left',
        tooltip: detailedViewUtil.tooltip,
        active: false,
        onClick: () => {
          const budgetItems = budgetItemsFromSelection(
            newCellRange || visibleNodes, gridRef.current
          );

          if (budgetItems.length) {
            appDispatch(updateDetailedViewSettings({
              type: DetailedViewType.MODAL,
              templateId: templateId,
              sectionKey: sectionKeys.budgeting,
              data: {
                budgetItems
              }
            }));
          } else {
            setDetailedViewData(newCellRange ? newCellRange : visibleNodes);
          }

        },
      },
      {
        key: 'addRow',
        type: 'button',
        disabled: !canAddRow(newCellRange, visibleNodes),
        onClick: () => {
          // ! HACK: When all parent elements are selected in PP agGrid returns only children
          if (visibleNodes?.at(1)?.parent?.isSelected()) {
            visibleNodes?.at(1)?.parent?.setExpanded(true);
          }

          if (newCellRange && newCellRange.length) {
            addRow(
              gridRef.current?.api?.getDisplayedRowAtIndex(newCellRange.at(-1)?.startRow.rowIndex)
            );
          } else if (visibleNodes && visibleNodes?.length) {
            addRow(visibleNodes.at(-1));
          }

        },
        hidden: false,
        keyBinding: {
          ...KEY_BINDINGS.addRow,
          keyBindingNode: { current: tableElement },
          disabled: isKeyboardDisabled
        },
        icon: addRowButton.icon,
        tooltip: addRowButton.tooltip,
        active: false,
        placement: addRowButton.placement
      },
      {
        key: 'removeSelected',
        type: 'button',
        keyBinding: {
          ...KEY_BINDINGS.removeSelected,
          disabled: isKeyboardDisabled,
          keyBindingNode: { current: tableElement },
        },
        disabled: !canDeleteSelected(newCellRange, visibleNodes),
        onClick: () => {
          setRowsForRemove(
            (rowsInRange.length ? rowsInRange : visibleNodes)
              // Make sure we only remove new budget items
              ?.filter(({ data }) => data?.type === RowType.NEW_BUDGET_ITEM),
            Array.from(new Set(newCellsInRange?.map(({ column }) => column)))
          );
        },
        hidden: false,
        icon: removeSelectedButton.icon,
        tooltip: removeSelectedButton.tooltip,
        active: false,
        placement: removeSelectedButton.placement
      },
      {
        key: 'overviewDetails',
        type: 'button',
        keyBinding: {
          ...KEY_BINDINGS.overviewDetails,
          keyBindingNode: { current: tableElement },
          disabled: isKeyboardDisabled
        },
        disabled: !newCellsInRange?.length,
        onClick: () => {
          const overviewFor = newCellsInRange
            ?.map(({ node, column }) => ({
              node: node.id,
              column
            }))
            .reduce((acc, item) => {
              acc.nodes.add(item.node);
              acc.columns.add(item.column);
              return acc;
            }, { nodes: new Set<string>(), columns: new Set<string>() });
          appDispatch(financialsSlice.actions.setOverviewDropdown({
            nodes: Array.from(overviewFor.nodes),
            columns: Array.from(overviewFor.columns)
          }));
        },
        hidden: false,
        className: overviewDetailsButton.className,
        icon: overviewDetailsButton.icon,
        tooltip: overviewDetailsButton.tooltip,
        active: false,
        placement: overviewDetailsButton.placement
      },
    ]);
  };

  useEffect(() => {
    if (!isTableActive || (!cellRange && !selectedNodes?.length)) {
      return setUtilsState([]);
    }
    hydrateButtons(cellRange, selectedNodes);
  }, [
    cellRange,
    selectedNodes,
    templateId,
    isTableActive,
    isKeyboardDisabled,
    tableElement,
    cellRange?.at(-1)?.startRow?.rowIndex,
    cellRange?.at(-1)?.endRow?.rowIndex,
    cellRange?.at(-1)?.columns
  ]);

  useCommandBarEffect({ utils: utilsState });
  return undefined;
};

export default useCellCommandBar;
