import React from 'react';
import { Control, Controller, useWatch } from 'react-hook-form';
import InputGroupWithIcon from './InputGroupWithIcon';
import DatePickerCellEditor from './DatePickerCellEditor';
import { TableColDef } from 'components/elements/tableWrapper/types/table.types';

import styles from './Editors.module.scss';
import { fieldToIconMap } from '../budgetOverview.utils';
import { useTranslation } from 'react-i18next';

interface FormConfig {
  disabled?: boolean;
  message?: string;
  messageType?: 'warning' | 'error' | 'info';
}

interface EditorProps {
  field: TableColDef;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  formConfig: Record<string, FormConfig>;
  placeholder?: string;
}

const StartEndDateEditor = ({
  field,
  control,
  formConfig,
  placeholder
}: EditorProps) => {
  const [ t ] = useTranslation('financials');

  const startDate = useWatch({ control, name: 'startDate' });
  const endDate = useWatch({ control, name: 'endDate' });

  return <div className={ styles.doubleFiledEditor }>
    <Controller
      name='startDate'
      control={ control }
      render={ ({ field: { value, onChange } }) =>
        <InputGroupWithIcon
          disabled={ formConfig[ 'startDate' ]?.disabled }
          hasValue={ value }
          prefixIcon={ fieldToIconMap[ 'startDate' ] }
        >
          <DatePickerCellEditor
            value={ value }
            disabled={ formConfig[ 'startDate' ]?.disabled }
            setValue={ onChange }
            placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
            comparison={ field.cellEditorParams?.comparison ?? '' } 
            type='before'
            comparisonValue={ endDate }
            comparisonFieldName={ t('inline-edit.fields.endDate') }
          />
        </InputGroupWithIcon>
      }
    />
    <Controller
      name='endDate'
      control={ control }
      render={ ({ field: { value, onChange } }) =>
        <InputGroupWithIcon
          disabled={ formConfig[ 'endDate' ]?.disabled }
          hasValue={ value }
          prefixIcon={ fieldToIconMap[ 'endDate' ] }
        >
          <DatePickerCellEditor
            value={ value }
            disabled={ formConfig[ 'endDate' ]?.disabled }
            setValue={ onChange }
            placeholder={ placeholder ?? field?.cellEditorParams?.placeholder }
            comparison={ field.cellEditorParams?.comparison ?? '' } 
            type='after'
            comparisonValue={ startDate }
            comparisonFieldName={ t('inline-edit.fields.startDate') }
          />
        </InputGroupWithIcon>
      }
    />
  </div>;
};

export default StartEndDateEditor;