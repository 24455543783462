import React, { useCallback, useMemo, useRef } from 'react';
import Capsule from 'components/elements/capsule/Capsule';
import {
  UnassignFunction
} from 'components/elements/dimensionLeftPanel/labelingTypes';
import { useTranslation } from 'react-i18next';
import { AgGridReact } from 'ag-grid-react';
import { Dimension as DimensionType } from 'types/filterTable.types';
import { getUUID } from '../../../../../../../utils/templates.utils';
// eslint-disable-next-line max-len
import useDropZoneEffect from 'components/elements/dimensionLeftPanel/listView/dimensionDetails/detailsList/hooks/useDropZoneEffect';
import styles from '../DimensionItem/DimensionItem.module.scss';
import { IRowNode } from 'ag-grid-community';
import HighlightedText from '../../../../../highlightedText/HighlightedText';
import { useSearchable } from '../../../../../../../context/SearchableContext';
import { useDrag } from 'react-dnd';
import { RowType } from 'types/financials.types';
import {
  createDimensionItemNode,
  createUnassignedDimensionItem
} from 'utils/template.utils';
import { createNode } from 'store/template.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks/hooks';
import { selectCustomFormula } from 'store/formula.slice';
import { isProductProxy } from 'utils/financials.utils';

interface Props {
  isActive: boolean;
  onClick: () => void;
  unassignNodesCallback: UnassignFunction;
  gridRef: React.RefObject<AgGridReact>;
  isLabeling: boolean;
  dimension?: DimensionType;
  isListView: boolean;
  isTemplateBuilder: boolean;
}

const UnassignedButton = ({
  isActive,
  onClick,
  gridRef,
  unassignNodesCallback,
  dimension,
  isListView,
  isLabeling,
  isTemplateBuilder,
}: Props) => {
  const [ t ] = useTranslation('financials');
  const dropId = useRef(getUUID());
  const { state: { search } } = useSearchable();
  const customFormula = useAppSelector(selectCustomFormula);
  const customFormulaIsOpen = useMemo(() => customFormula !== null ,[ customFormula ]);
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ { isDragging }, drag ] = useDrag(() => {
    return {
      type: 'tag',
      item:  {
        rowData: createUnassignedDimensionItem(dimension),
        children: [],
        childrenNodes: [],
        type: RowType.DIMENSION_ITEM
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId()
      }),
    };
  });

  const onDoubleClick = useCallback(() => {
    if (!customFormulaIsOpen) {
      const newDimension = createUnassignedDimensionItem(dimension);
      const unassignedTemplateNode = createDimensionItemNode(newDimension);
      dispatch(createNode(unassignedTemplateNode, null, 'none'));
    }
  }, [ customFormulaIsOpen, dimension ]);

  const onAssign = useCallback((nodes: IRowNode[], showModal: boolean) => {
    let _nodes = nodes;
    // ! HACK: Fixes issue with drag PP ignores selection
    if (nodes.length === 1 && isProductProxy(nodes.at(0)) && nodes.at(0).isSelected()) {
      _nodes = gridRef.current.api.getSelectedNodes();
    }

    unassignNodesCallback(
      _nodes,
      dimension,
      showModal
    );
  }, [ unassignNodesCallback ]);

  useDropZoneEffect({
    dropId: dropId.current.toString(),
    gridRef,
    onAssign
  });

  return (
    <span className={ styles.dropdownWrapper } ref={ isTemplateBuilder ? drag : null }>
      <Capsule
        id={ dropId.current.toString() }
        isActive={ isLabeling ? true : isActive }
        onClick={ isLabeling ? () => null : onClick }
        onDoubleClick={ onDoubleClick }
        className={ `${ styles.capsule } ${ isListView ? styles.columnList : '' }` }
      >
        <HighlightedText
          text={ t('left-panel.unassigned') }
          highlight={ search }
        />
      </Capsule>
    </span>

  );
};

export default UnassignedButton;
