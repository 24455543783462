import { useCallback, useEffect, useMemo, useState } from 'react';
import { GroupOption, TableColDef } from 'components/elements/tableWrapper/types/table.types';
import { useTranslation } from 'react-i18next';
import useTableRenderer from 'components/elements/tableWrapper/hooks/useTableRenderer';
import {
  InputCategory,
  InputCustomValue,
  InputDataType,
  InputRowWithValues,
  InputSource,
} from 'components/inputs/types/inputs.types';
import TextCellEditor from 'components/elements/tableWrapper/table/editors/TextCellEditor';
import NumericCellEditor from 'components/elements/tableWrapper/table/editors/NumericCellEditor';
import SelectCellEditor from 'components/elements/tableWrapper/table/editors/SelectCellEditor';
import useTableFormatter from 'components/elements/tableWrapper/hooks/useTableFormatter';
import useTableEditableProps from '../../../elements/tableWrapper/hooks/useTableEditableProps';
import { Period } from '../../../../types/financials.types';
import useGrid from '../../../../hooks/useGrid';
import {
  ColDef,
  EditableCallback,
  EditableCallbackParams,
  ICellEditorParams,
  ICellRendererParams,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams
} from 'ag-grid-community';
import { Cadence } from 'types/form.types';
import useTableColumns from 'components/elements/tableWrapper/hooks/useTableColumns';
import { cloneDeep, debounce } from 'lodash';
import styles from '../InputsTable.module.scss';
import tableStyles from 'components/elements/tableWrapper/table/Table.module.scss';
import { separateThousands } from 'utils/financials.utils';
import {
  INPUT_NONE_GROUP_ID,
  INPUT_NONE_GROUP_NAME,
  INPUT_TEMPLATE_ID
} from 'components/inputs/utils/inputs.utils';
import { getDisplayName } from '../../../../utils/common.utils';
import useAssignDimension from 'components/elements/tableWrapper/hooks/useAssignDimension';
import { Dimension } from 'types/filterTable.types';
import { ReportType } from '../../../../types/templates.types';

interface Props {
  search: string;
  period: Period | null;
  update: (input: InputRowWithValues) => void;
  categories: InputCategory[];
}

const useInputsTable = ({ search, period, update, categories }: Props) => {
  const [ t ]= useTranslation('inputs');
  const { renderField, valueRenderer, cellRenderer } = useTableRenderer({ search });
  const { editableColumnDef } = useTableEditableProps();
  const { cadenceFormatter } = useTableFormatter();
  const { numberColumnDef, getDimensionColumns } = useTableColumns({ search });

  const { onAssignDimension } = useAssignDimension();
  const { getColumnDefs } = useGrid({
    showDynamicColumns: false,
    period: {
      ...period,
    },
    templateId: INPUT_TEMPLATE_ID,
    formatField: (field: string) => field,
    getHeaderId: ({ reportType }) => reportType,
    getHeaderName: ({ date, reportType, cadence }) => {
      if (cadence === Cadence.year) {
        if (reportType === ReportType.PLAN) {
          return t('financials:budget');
        } else {
          return t('financials:actuals');
        }
      }
      if (cadence === Cadence.month) {
        return date.format('MMM \'YY');
      }
    },
  }, styles);

  const [ changes, setChanges ] = useState<InputRowWithValues>(null);

  const applyChanges = useCallback(debounce((_changes: InputRowWithValues[]) => {
    _changes.forEach((item) => update(item));
    setChanges(null);
  }, 20), []);

  useEffect(() => {
    if (changes) {
      applyChanges([ changes ]);
    }
  }, [ changes ]);

  useEffect(() => {
    return () => {
      setChanges(null);
      applyChanges.cancel();
    };
  }, []);

  const defaultValueFormatter = (params: ValueFormatterParams<InputRowWithValues>) => {
    if (!params.value || !params.data?.dataType) {
      return undefined;
    }

    return {
      [ InputDataType.INTEGER ]: `${ separateThousands(params.value) }`,
      [ InputDataType.NUMBER ]: `${ separateThousands(params.value) }`,
      [ InputDataType.PERCENTAGE ]: `${ separateThousands(params.value) }%`,
      [ InputDataType.CURRENCY ]: `${ separateThousands(params.value) }€`,
      [ InputDataType.DATE ]: `${ params.value }.`,
      [ InputDataType.DATE_OFFSET_DAYS ]: `${ params.value }`,
      [ InputDataType.DATE_OFFSET_MONTHS ]: `${ params.value }`,
    }[ params.data.dataType ];
  };

  const sourceRestrictedEditableColDef: ColDef<InputRowWithValues> = {
    ...editableColumnDef,
    editable: (params) => {
      const baseEditableCallback = editableColumnDef.editable as EditableCallback;
      return baseEditableCallback(params) && params.data.source === InputSource.USER;
    }
  };

  const getTranslationOrNone = (key: string, value: string) => {
    if (!value) {
      return undefined;
    }
    return t(`${ key }.${ value }`);
  };

  const valueCellEditorParams = (
    params: ICellEditorParams<InputRowWithValues>,
    optional = false
  ) => {
    const dataType = params.data.dataType;
    const integerDataTypes = [
      InputDataType.INTEGER,
      InputDataType.DATE,
      InputDataType.DATE_OFFSET_DAYS,
      InputDataType.DATE_OFFSET_MONTHS,
    ];
    let rules = {};
    if (integerDataTypes.includes(dataType)) {
      rules = {
        disableDecimals: true
      };
    }
    if (dataType === InputDataType.DATE) {
      rules = {
        ...rules,
        min: 1,
        max: 31,
      };
    }
    if (dataType === InputDataType.DATE_OFFSET_DAYS ||
      dataType === InputDataType.DATE_OFFSET_MONTHS) {
      rules = {
        ...rules,
        min: -500,
        max: 500,
      };
    }
    return {
      ...rules,
      optional
    };
  };

  const removeValue = (params: ValueSetterParams<InputRowWithValues>, columnDef: TableColDef) => {
    const newInputRow = cloneDeep(params.data);
    const values = columnDef[ 'reportType' ] === ReportType.PLAN ? 'planValues' : 'actualValues';
    const indexOfValue = newInputRow[ values ].findIndex(val => val.period === columnDef.field);
    if (indexOfValue !== -1) {
      newInputRow[ values ].splice(indexOfValue, 1);
    }
    update(newInputRow);
  };

  const updateInputValue = (input: InputRowWithValues, value: InputCustomValue) => {
    const newInputRow = cloneDeep(input);
    const valuesToPush = value.plan ? 'planValues' : 'actualValues';
    const indexOfValue = newInputRow[ valuesToPush ].findIndex(val => val.period === value.period);
    delete value.plan;
    if (indexOfValue === -1) {
      newInputRow[ valuesToPush ].push(value);
    } else {
      newInputRow[ valuesToPush ][ indexOfValue ] = value;
    }

    return newInputRow;
  };

  const updateValueProxy = (input: InputRowWithValues, value: InputCustomValue) => {
    setChanges(prev => {
      if (prev) {
        return updateInputValue(prev, value);
      }
      return updateInputValue(input, value);
    });
  };

  const updateValue = (params: ValueSetterParams<InputRowWithValues>, columnDef: TableColDef) => {
    const columnReportType = columnDef[ 'reportType' ] as ReportType;
    const value = {
      period: columnDef.field,
      value: params.newValue.toString(),
      plan: columnReportType === ReportType.PLAN,
    };
    updateValueProxy(params.data, value);
  };

  const basicColumnStyle: ColDef = {
    width: 100,
    minWidth: 80,
    flex: 0,
    suppressFillHandle: true,
    pinned: 'left',
    lockPinned: true,
  };

  const basicColumnDef: TableColDef<InputRowWithValues>[] = [
    {
      ...renderField('input_id', 'id'),
      ...basicColumnStyle,
      valueGetter: (params: ValueGetterParams<InputRowWithValues>) => {
        return params.data?.id;
      },
      comparator: (a: number, b: number) => {
        // Nulls are always at the end, so that new added items are displayed on top of the list
        if (a === null || a === undefined) {
          return 1;
        }
        if (b === null || b === undefined) {
          return -1;
        }
        return a - b;
      },

    },
    {
      ...renderField('name', t('table.columns.name')),
      valueGetter: (params: ValueGetterParams<InputRowWithValues>) => {
        return getDisplayName(params.data?.name);
      },
      valueSetter: (params: ValueSetterParams<InputRowWithValues>) => {
        const newInputRow: InputRowWithValues = {
          ...params.data,
          [ params.colDef.field ]: {
            en: params.newValue,
            fi: null,
            sv: null,
          },
        };
        update(newInputRow);
        return true;
      },
      ...sourceRestrictedEditableColDef,
      cellEditor: TextCellEditor,
      ...basicColumnStyle,
      width: 140,
      compulsory: true,
    },
    {
      ...renderField('category', t('table.columns.category')),
      ...sourceRestrictedEditableColDef,
      compulsory: true,
      cellEditor: SelectCellEditor,
      cellEditorParams: {
        values: categories.map(category => category.id),
        formatValue: (value: number) => getDisplayName(
          categories.find(category => category.id === value)?.name),
      },
      valueGetter: (params: ValueGetterParams<InputRowWithValues>) => {
        if (params.data.category === INPUT_NONE_GROUP_ID) {
          return INPUT_NONE_GROUP_NAME;
        }
        return getDisplayName(
          categories.find(category => category.id === params.data.category)?.name);
      },
      valueFormatter: ({ value }) => value,
      ...basicColumnStyle,
      width: 160,
      rowGroup: true,
      hide: true,
    },
    {
      ...renderField('dataType', t('table.columns.data-type')),
      ...sourceRestrictedEditableColDef,
      compulsory: true,
      cellEditor: SelectCellEditor,
      cellEditorParams: {
        values: Object.values(InputDataType),
      },
      valueFormatter: ({ value }) => getTranslationOrNone('data-type', value),
      getQuickFilterText: ({ value }) => getTranslationOrNone('data-type', value),
      ...basicColumnStyle,
    },
    {
      ...renderField('cadence', t('table.columns.cadence')),
      valueFormatter: (params) => cadenceFormatter(params.value ?? Cadence.month),
      getQuickFilterText: (params) => cadenceFormatter(params.value ?? Cadence.month),
      ...basicColumnStyle,
    },
    {
      ...renderField('source', t('table.columns.source')),
      valueFormatter: ({ value }) => getTranslationOrNone('source', value),
      getQuickFilterText: ({ value }) => getTranslationOrNone('source', value),
      ...basicColumnStyle,
    },
    {
      ...renderField('defaultValue', t('table.columns.defaultValue')),
      ...editableColumnDef,
      compulsory: true,
      cellRenderer: (params: ICellRendererParams<InputRowWithValues>) => {
        if (params.data.dataType === InputDataType.DATE) {
          return cellRenderer(params);
        }
        return valueRenderer(params);
      },
      cellEditor: NumericCellEditor,
      ...numberColumnDef,
      cellEditorParams: valueCellEditorParams,
      ...basicColumnStyle,
      valueFormatter: defaultValueFormatter,
      getQuickFilterText: defaultValueFormatter,
    }
  ];

  const mapGridColumnDef = (columnDef: ColDef): TableColDef => ({
    ...columnDef,
    ...renderField(columnDef.field, columnDef.headerName),
    ...editableColumnDef,
    ...numberColumnDef,
    cellClass: () => {
      const classes = [ (numberColumnDef.cellClass as string), styles.valueCell ];
      const isPlan = columnDef[ 'reportType' ] === ReportType.PLAN;
      const isFirst = columnDef[ 'isFirst' ];
      if (isPlan && isFirst) {
        classes.push(styles.firstPlanColumnCell);
      }
      return classes;
    },
    type: null,
    cellEditor: NumericCellEditor,
    cellEditorParams: (params) => valueCellEditorParams(params, true),
    suppressMovable: true,
    resizable: false,
    flex: 0,
    width: 100,
    suppressHide: true,
    sortable: false,
    headerClass: () => {
      const isPlan = columnDef[ 'reportType' ] === ReportType.PLAN;
      const classes = [ tableStyles.header, styles.valueHeader ];
      if (!isPlan) {
        classes.push(styles.actualsHeader);
      }
      return classes;
    },
    valueGetter: (params: ValueGetterParams<InputRowWithValues>) => {
      const valueType = columnDef[ 'reportType' ] as ReportType;
      const isPlanColumn = valueType === ReportType.PLAN;
      const values = isPlanColumn ? params.data.planValues :
        params.data.actualValues;
      const defaultValue = params.data.defaultValue;
      if (values) {
        const foundValue = values.find(val => val.period === columnDef.field)?.value;
        if (isPlanColumn) {
          return foundValue ?? defaultValue;
        }
        return foundValue;
      }
      return defaultValue;
    },
    valueFormatter: defaultValueFormatter,
    getQuickFilterText: defaultValueFormatter,
    valueSetter: (params: ValueSetterParams<InputRowWithValues>) => {
      if (params.newValue == null) {
        removeValue(params, columnDef);
      } else {
        updateValue(params, columnDef);
      }
      return params.newValue !== params.oldValue;
    },
  });

  const baseValueColumnDefs = useMemo(() => {
    if (period) {
      return getColumnDefs(null, period.actualsOpen, period.planOpen, false);
    }
    return { columnDefs: [], columnFields: [] };

  }, [ period ]);

  const valueColumnDefs = useMemo(() => {
    return baseValueColumnDefs.columnDefs.map(colDef => ({
      ...colDef,
      children: colDef.children.map(mapGridColumnDef)
    }));
  }, [ baseValueColumnDefs, search ]);

  const onChangeDimensionItem = useCallback(async (
    node: IRowNode<InputRowWithValues>,
    id: number,
    dimension: number
  ) => {
    const updatedNode = onAssignDimension<InputRowWithValues>(node, id, dimension);
    update(updatedNode);
  }, [ onAssignDimension ]);

  const canAssignDimension = useCallback((
    params: EditableCallbackParams<InputRowWithValues>,
    dimension: Dimension
  ) => {
    return dimension.canBeAssigned && params.data.source === InputSource.USER;
  }, []);

  const dimensionColumns = useMemo(() => {
    return getDimensionColumns(onChangeDimensionItem, canAssignDimension, false).map(col => ({
      ...col,
      ...basicColumnStyle,
    }));

  }, [ getDimensionColumns ]);

  const groupByOptions: GroupOption[] = useMemo(() => {
    return basicColumnDef.map(child => ({
      field: child.field,
      name: child.headerName,
    })).concat(dimensionColumns.map(child => ({
      field: child.field,
      name: child.headerName,
      groupName: t('common:dimensions')
    })));
  }, [ basicColumnDef, dimensionColumns ]);

  return useMemo(() => {
    const defaultValueCol = basicColumnDef.pop();
    return ({
      columnDefs: [
        {
          headerName: '',
          marryChildren: true,
          headerGroupComponent: () => null,
          children: [ ...basicColumnDef, ...dimensionColumns, defaultValueCol ],
        },
        ...valueColumnDefs
      ],
      groupByOptions,
      dimensionColumns
    });
  }, [ valueColumnDefs, search, categories, dimensionColumns, groupByOptions ]);
};

export default useInputsTable;
