import React, {
  ChangeEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Input, InputRef } from 'antd';
import { ICellEditorParams } from 'ag-grid-community';
import styles from './FinancialCellEditor.module.scss';
import { useFormulaRow } from 'components/formulaRow/FormulaRowContext';
import { ReportType } from 'types/templates.types';

const FinancialCellEditor = forwardRef((props: ICellEditorParams, ref) => {
  const [ value, setValue ] = useState(getInitialValue(props.charPress, props.value));

  const [ editingInFormula, setEditingInFormula ] = useState(false); 

  const inputRef = useRef<InputRef>(null);

  const { dispatch, state: { open, formula } } = useFormulaRow();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    if (open) {
      dispatch({ type: 'SAVE', payload: val?.toString() });
    }

    setValue(val);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  useLayoutEffect(() => {
    inputRef.current.focus();

    // ? this will confirm value which was pasted or already entered in another cell
    if (props.charPress === null && value != null && value !== '') {
      props.stopEditing();
      return;
    }

    if (props.charPress === '=') {
      setEditingInFormula(true);
      dispatch({ type: 'OPEN', payload: props.value as string });
    }

    return () => {
      dispatch({ type: 'CLOSE' });
    };
  }, []);

  useEffect(() => {
    if (open && formula != null && formula != value?.toString()) {
      setValue(formula);
    }

    // ? this should be triggered by saving the formula
    if (formula === '' && !open && editingInFormula) {
      props.stopEditing();

      const colId = props.column.getColId();
      const rowIndex = props.rowIndex;

      setTimeout(() => {
        setEditingInFormula(false);
        props.api.setFocusedCell(rowIndex, colId);
      }, 100);
    }
    
  }, [ open, formula ]);

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const colId = props.column.getColId();
    const isStartOfFormula = colId?.startsWith(ReportType.PLAN) && e.key === '=';

    if (isStartOfFormula && !open) {
      setEditingInFormula(true);
      dispatch({ type: 'OPEN' });
    }
  };

  return (
    <Input
      ref={ inputRef }
      value={ value }
      className={ styles.numericCell }
      onChange={ onChange }
      onKeyPress={ onKeyPress }
    />

  );
});

FinancialCellEditor.displayName = 'FinancialCellEditor';
export default FinancialCellEditor;

function getInitialValue(charPressed: string | null, value: string | null) {
  if (charPressed != null) {
    if (charPressed === '=') {
      return value;
    }

    return charPressed;
  } else {
    return value;
  }
}
