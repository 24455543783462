import React, {
  MouseEvent,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { GroupCellRendererParams, IRowNode, RowEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useAppSelector } from 'store/hooks/hooks';
import { FinancialNode, RowType } from 'types/financials.types';
import { isLazyLoadingRow, isProductProxy } from 'utils/financials.utils';
import { getDefaultChartSettings, IGNORE_NODES } from 'utils/chart.utils';
import { insertNewRow, NO_ADD_BUTTON_ROW_TYPES } from 'components/financials/utils/addRow.utils';
import RowTitleRenderer from './RowTitleRenderer';

import { ReactComponent as ChartIcon } from 'assets/icons/chartIcon.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DragIcon } from 'assets/icons/drag.svg';
import { ReactComponent as ExpandArrow } from 'assets/icons/chevron-right.svg';
import { ReactComponent as LabelIcon } from 'assets/icons/label.svg';

import styles from './CustomGroupCellRenderer.module.scss';
import { FinancialTableContext } from '../../../../context/FinancialTableContext';

const BASE_INDENT = 20;

interface Props extends GroupCellRendererParams {
  gridRef: RefObject<AgGridReact>;
  templateId: number;
  grayedOutNodeIds: string[];
}

const NOT_DRAGGABLE_ROW_TYPES = [
  RowType.TITLE,
  RowType.SUM_UP,
  RowType.SUBTOTAL,
  RowType.TOTAL,
  RowType.FORMULA,
  RowType.SPACER,
  RowType.HALF_SPACER,
];

const CustomGroupCellRenderer = (props: Props) => {
  const { node, templateId } = props;

  const [ t ] = useTranslation('financials');

  const { dispatch } = useContext(FinancialTableContext);

  const budgetItemTypes = useAppSelector(state => state.budget.budgetItemTypes);

  const [ expanded, setExpanded ] = useState(node.expanded);
  const dragRef = useRef<HTMLDivElement>();

  const shouldShowDragIcon = useMemo(() => {
    return !NOT_DRAGGABLE_ROW_TYPES.includes(node.data?.type);
  }, [ node ]);

  const shouldShowLabelIcon = useMemo(() => {
    return props.grayedOutNodeIds?.includes(node.id);
  }, [ node, props.grayedOutNodeIds ]);

  useEffect(() => {
    if (dragRef.current && !NOT_DRAGGABLE_ROW_TYPES.includes(node.data?.type)) {
      props.registerRowDragger(dragRef.current);
    }
  }, [ dragRef.current, props ]);

  useEffect(() => {
    const expandListener = (event: RowEvent) => {
      setExpanded(event.node.expanded);

      if (event.node.allLeafChildren.length) {
        event.node.allLeafChildren.forEach((child) => {
          if (child?.data?.type === RowType.BREAKDOWN) {
            child.setExpanded(true);
          }
        });
      }
    };

    node.addEventListener('expandedChanged', expandListener);

    return () => {
      node.removeEventListener('expandedChanged', expandListener);
    };
  }, []);

  const onClick = useCallback((e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    node.setExpanded(!node.expanded);
  }, [ node ]);

  const isNewRow = (rowNode: IRowNode): boolean => {
    return rowNode?.data?.type === RowType.NEW_BUDGET_ITEM;
  };

  const showExpandArrow = (rowNode: IRowNode<FinancialNode>): boolean => {
    // Hide expand arrow for DTs
    return rowNode.isExpandable() &&
    rowNode?.data?.type !== RowType.BREAKDOWN &&
    // Lazy loading rows have their own expand button
    !isLazyLoadingRow(rowNode) && (rowNode.data?.hasTransactions || isProductProxy(rowNode));
  };

  const getPaddingForNode = (rowNode: IRowNode): number => {
    return (rowNode.level * BASE_INDENT);
  };

  const addRow = (rowNode: IRowNode) => {
    insertNewRow(props.gridRef.current, rowNode, budgetItemTypes);
  };

  const isTemplateNode = useCallback((rowNode: IRowNode) => {
    return rowNode.data.uuid != null;
  }, []);

  const shouldShowChartIcon = useCallback((rowNode: IRowNode): boolean => {
    return !isProductProxy(node) &&
      !node.data?.isFailed &&
      !IGNORE_NODES.includes(rowNode.data?.type) &&
      isTemplateNode(rowNode);
  }, []);

  const shouldShowAddIcon = (rowNode: IRowNode) => {
    if (rowNode.data?.isFailed) {
      return false;
    }
    return !NO_ADD_BUTTON_ROW_TYPES.includes(rowNode.data?.type) &&
      props.columnApi.getAllGridColumns().some((column) => column.getColId().startsWith('plan'));
  };

  const onClickChartIcon = useCallback((rowNode: IRowNode) => {
    dispatch({ type: 'setChartVisible', payload: true });
    dispatch({ type: 'setChartSettings', payload: getDefaultChartSettings({
      primary: rowNode.data.uuid,
    }) });
  }, []);

  return (
    <span className='ag-group-value'>
      <div ref={ dragRef } className={ clsx(styles.cell) }>
        { isNewRow(node) && <div className='newRowBadge'>{ t('table.new-row') }</div> }
        { shouldShowLabelIcon ? <LabelIcon className={ styles.labelIcon }/> : <DragIcon
          className='dragIcon'
          style={ { visibility: shouldShowDragIcon ? 'initial' : 'hidden' } }
        />
        }
        <div
          className={ styles.cell }
          style={ { paddingLeft: `${ getPaddingForNode(node) }px` } }
        >
          { // Lazy loading row components have their own expand button
            !isLazyLoadingRow(node) && <button
              className={
                clsx(
                  styles.expandArrowButton,
                  { [ 'ag-group-contracted' ]: !expanded },
                  { [ styles.hidden ]: !showExpandArrow(node) }
                )
              }
              type='button'
              onClick={ onClick }
            >
              <ExpandArrow
                style={ { transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)' } }
                className={ clsx(styles.expandIcon, 'expandArrow') }
              />

            </button>
          }

          <RowTitleRenderer { ...props } templateId={ templateId } gridRef={ props.gridRef }/>
        </div>

        <div className={ clsx(styles.actions, 'cell-actions') }>
          {
            shouldShowChartIcon(node) ?
              <button
                type='button'
                aria-label='open chart'
                className={ styles.chartButton }
                onClick={ (e) => {
                  e.stopPropagation();
                  onClickChartIcon(node);
                } }>
                <ChartIcon/>
              </button>
              : null
          }

          {
            shouldShowAddIcon(node) ?
              <button
                type='button'
                aria-label='Add new row'
                className={ styles.addRowButton }
                onClick={ (e) => {
                  e.stopPropagation();
                  addRow(node);
                } }>
                <PlusIcon/>
              </button> :
              null
          }
        </div>
      </div>
    </span>
  );
};

export default CustomGroupCellRenderer;
