import React, { useCallback, useEffect, useState } from 'react';
import { useFormulaRow } from './FormulaRowContext';
import styles from './FormulaRow.module.scss';
import { ReactComponent as FxIcon } from 'assets/icons/fx-icon.svg';
import ResizableContainer from 'components/elements/resizableContainer/ResizableContainer';
import { ReactComponent as DoubleChevronDown } from 'assets/icons/double-chevron-down.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { Input } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import AcceptFormulaModal
  from 'components/templates/templateRow/accpetFormulaModal/AcceptFormulaModal';
import Button from 'components/elements/button/Button';
import { useTranslation } from 'react-i18next';

const DEFAULT_SIZE = 45;
const MAX_SIZE = 200;

export const FormulaRow = () => {
  const [ t ] = useTranslation('common');

  const { dispatch, state: { open, formula: contextValue } } = useFormulaRow();
  const [ defaultResizeSize, setDefaultResizeSize ] = useState(0);
  const [ iconRotated, setIconRotated ] = useState(false);
  const { control, watch, reset } = useForm({
    defaultValues: {
      formula: '',
    }
  });
  const [ isFormulaSaved, setIsFormulaSaved ] = useState<boolean>(false);
  const [ acceptFormulaModal, setAcceptFormulaModal ] = useState(false);

  const formula = watch('formula');

  const trimStartingEqualSign = useCallback((val: string) => {
    if (val?.startsWith('=')) {
      return val.slice(1);
    }
    return val;
  }, []);

  useEffect(() => {
    reset({ formula: trimStartingEqualSign(contextValue) });
  }, [ contextValue ]);

  useEffect(() => {
    setIsFormulaSaved(false);
  }, [ formula ]);

  const closeFormula = useCallback(() => {
    dispatch({ type: 'CLOSE' });
    reset({ formula: '' });
  }, []);

  const saveFormula = useCallback(() => {
    setIsFormulaSaved(true);
    dispatch({ type: 'SAVE', payload: formula });
    setTimeout(() => {
      closeFormula();
    });
  }, [ formula ]);

  const closeFormulaRow = useCallback(() => {

    if (isFormulaSaved || formula.trim().length === 0) {
      closeFormula();
    } else {
      setAcceptFormulaModal(prev => !prev);
    }
  }, [ isFormulaSaved, formula ]);

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      saveFormula();
    }

    if (e.key === 'Escape') {
      e.preventDefault();
      closeFormulaRow();
    }
  }, [ saveFormula, closeFormula, setAcceptFormulaModal ]);

  return (
    <div className={ `${ styles.row } ${ !open ? styles.hidden : '' }` }>
      <ResizableContainer
        dimensionToResize='height'
        defaultSize={ defaultResizeSize ? defaultResizeSize : DEFAULT_SIZE }
        minSize={ DEFAULT_SIZE }
        maxSize={ MAX_SIZE }
        onSizeChange={ (size) => setIconRotated(size === MAX_SIZE) }
        className={ styles.innerRow }
      >
        <div className={ styles.formulaPrefix }>
          <FxIcon className={ styles.fxIcon } />
          =
        </div>
        <div className={ styles.formulaContainer }>
          <Controller
            name='formula'
            control={ control }
            render={ ({ field }) => (
              <Input.TextArea
                { ...field }
                onKeyDown={ handleKeyDown }
                className={ styles.formulaInput }
                autoSize
              />
            ) }
          />
        </div>
        <div
          className={ `${ styles.resizeArrow } ${ iconRotated ? styles.iconRotated : '' }` }
          onClick={ () => {
            if (defaultResizeSize) {
              setDefaultResizeSize(0);
            } else {
              setIconRotated(true);
              setDefaultResizeSize(MAX_SIZE);
            }
          }
          }
        >
          <DoubleChevronDown />
        </div>
      </ResizableContainer>
      <div className={ styles.buttonsContainer }>
        <Button
          className={ styles.saveButton }
          onClick={ saveFormula }
        >
          { t('form.save') }
        </Button>
        <CloseIcon className={ styles.closeIcon } onClick={ closeFormulaRow }/>
      </div>
      <AcceptFormulaModal
        isVisible={ acceptFormulaModal }
        onClose={ () => {
          setAcceptFormulaModal(false);
        } }
        onConfirm={ closeFormula }
      />
    </div>
  );
};
