import React from 'react';
import { ReactComponent as FilterIcon } from 'assets/icons/filterv2.svg';
import { ReactComponent as LabelIcon } from 'assets/icons/label.svg';
import { ReactComponent as SortIcon } from 'assets/icons/sorting.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/group.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/filter.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/pdfSign.svg';
import { ReactComponent as ExportIcon } from 'assets/icons/download.svg';
import { ReactComponent as DetailedViewIcon } from 'assets/icons/detailed-view.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/magic.svg';
import { ReactComponent as ModeIcon } from 'assets/icons/edit-fill.svg';
import { ReactComponent as ColumnIcon } from 'assets/icons/column.svg';
import { ReactComponent as LayoutIcon } from 'assets/icons/layout.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as TextLensIcon } from 'assets/icons/text-lens.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash-bin.svg';
import {
  UtilKey,
  CommandBarUtil,
  CommandBarCommonProps
} from 'components/commandBar/types/commandBar.types';
import { t } from 'i18next';
import { RIGHT_SIDE_KEYS, UTIL_KEYS } from '../constants/commandBar.constants';

import styles from '../CommandBar.module.scss';

const getButtonIcon = (key: UtilKey) => {
  return {
    [ 'filter' ]: <FilterIcon />,
    [ 'label' ]: <LabelIcon />,
    [ 'sort' ]: <SortIcon />,
    [ 'group' ]: <GroupIcon />,
    [ 'layout' ]: <LayoutIcon />,
    [ 'pdf' ]: <PdfIcon />,
    [ 'export' ]: <ExportIcon />,
    [ 'detailed-view' ]: <DetailedViewIcon />,
    [ 'settings' ]: <SettingsIcon />,
    [ 'chat' ]: <ChatIcon />,
    [ 'mode' ]: <ModeIcon />,
    [ 'columns' ]: <ColumnIcon />,
    [ 'addRow' ] : <PlusIcon />,
    [ 'removeSelected' ] : <TrashIcon />,
    [ 'overviewDetails' ]: <TextLensIcon />
  }[ key ];
};

export const getPlacement = (key: UtilKey) => {
  if (RIGHT_SIDE_KEYS.includes(key)) {
    return 'right';
  }
  return 'left';
};

const getDefaultSetting = (key: UtilKey): CommandBarUtil => {
  const defaultProps: CommandBarCommonProps = {
    key,
    type: 'button',
    hidden: true,
    disabled: true,
    icon: getButtonIcon(key),
    placement: getPlacement(key),
    /**
     * t(`common:command-bar.tooltip.filter`)
     * t(`common:command-bar.tooltip.label`)
     * t(`common:command-bar.tooltip.sort`)
     * t(`common:command-bar.tooltip.group`)
     * t(`common:command-bar.tooltip.detailed-view`)
     * t(`common:command-bar.tooltip.pdf`)
     * t(`common:command-bar.tooltip.export`)
     * t(`common:command-bar.tooltip.settings`)
     * t(`common:command-bar.tooltip.chat`)
     * t(`common:command-bar.tooltip.mode`)
     * t(`common:command-bar.tooltip.addRow`)
     * t(`common:command-bar.tooltip.removeSelected`)
     * t(`common:command-bar.tooltip.layout`)
     * t(`common:command-bar.tooltip.overviewDetails`)
     */
    tooltip: t(`common:command-bar.tooltip.${ key }`),
  };

  if (key === 'cta') {
    return {
      ...defaultProps,
      type: 'select',
      options: [],
      name: undefined,
    };
  }
  if (key.startsWith('divider')) {
    return {
      ...defaultProps,
      type: 'divider',
    };
  }
  if (key === 'save') {
    return {
      ...defaultProps,
      type: 'button-text',
      active: false,
      placement: getPlacement(key),
      onClick: undefined,
      name: t('common:modal.buttons.default-ok')
    };
  }
  if (key === 'overviewDetails') {
    return {
      ...defaultProps,
      type: 'button',
      active: false,
      onClick: undefined,
      className: styles.lensButton
    };
  }
  return {
    ...defaultProps,
    type: 'button',
    active: false,
    onClick: undefined
  };
};

export const getDefaultUtilsSetting = () => {
  return UTIL_KEYS.map(key => getDefaultSetting(key));
};

// Filter out all match element at the start/end of the array
export const trimElementsOfType = <T extends { type: string }>(array: T[], match: RegExp): T[] => {
  // Find the index of the first element with a type different from typeToRemove
  let startIndex = 0;
  while (startIndex < array.length && match.test(array[ startIndex ].type) ) {
    startIndex++;
  }

  // Find the index of the last element with a type different from typeToRemove
  let endIndex = array.length - 1;
  while (endIndex >= 0 && match.test(array[ endIndex ].type)) {
    endIndex--;
  }

  // Slice the array to keep only the elements between startIndex and endIndex
  return array.slice(startIndex, endIndex + 1);
};
