import { ChartNode, ChartSource, ChartValues } from './../types/chart.types';
import { FormulaNodeRowData, ReportDataValues, ReportType } from './../types/templates.types';
import { useCallback } from 'react';
import { getDisplayName } from 'utils/common.utils';
import isEmpty from 'lodash/isEmpty';
import { isObject } from 'lodash';
import { DateValue } from 'types/chart.types';
import { getObjectValuesByDate, getValuesByDate, sumValuesByDate, } from 'utils/chart.utils';
import { RowType } from '../types/financials.types';
import { Dimension, DimensionItem } from '../types/filterTable.types';
import { getUnassignedNodeName } from '../utils/financials.utils';

const useChartConfig = () => {
  const getChartData = useCallback((
    nodes: ChartNode[],
    source: ChartSource
  ): ChartValues[] => {
    const actualData = mapChartData(nodes, ReportType.ACTUAL);
    const planData = mapChartData(nodes, ReportType.PLAN);
    const planDataWithoutRepetitions = planData.filter(
      d => !actualData.some(a => a.date === d.date)
    );

    if (source === ChartSource.ACTUALS) {
      return actualData;
    }
    if (source === ChartSource.BUDGET) {
      return planData;
    }
    return [
      ...actualData,
      ...planDataWithoutRepetitions
    ];
  }, []);

  const isNonUnassignedBreakdownNode = useCallback((node: ChartNode) => {
    return (
      node.type === RowType.BREAKDOWN ||
      node.type === RowType.DIMENSION_ITEM
    ) && node.rowData.name !== 'Unassigned';
  }, []);

  const mapChartData = useCallback((
    roots: ChartNode[],
    reportType: ReportType
  ): ChartValues[] => {
    const nonEmptyRoots =
        roots.filter(root => root && !isEmpty(root.data?.[ reportType ]));
    let returnValue: ChartValues[] = [];
    nonEmptyRoots.forEach(root => {
      let valuesByDate: DateValue[] = [];
      const data = root.data[ reportType ];
      const isEntryObject = Object.values(data).some(el => isObject(el));
      if (isEntryObject) {
        valuesByDate = getObjectValuesByDate(data as ReportDataValues);
      } else {
        valuesByDate = getValuesByDate(data as ReportDataValues);
      }

      /**
       * This type determines the name of the chart series displayed in legend.
       */
      const getType = (node: ChartNode) => {
        if (isNonUnassignedBreakdownNode(node)) {
          return getDisplayName((root.rowData as Dimension | DimensionItem).customName);
        } if (node.type === RowType.UNASSIGNED) {
          return getUnassignedNodeName(node.rowData as Dimension);
        }
        return getDisplayName(root.rowData.name);
      };

      const summedValues = sumValuesByDate(
        valuesByDate,
        getType(root),
        reportType,
        root.type === RowType.FORMULA ? (root.rowData as FormulaNodeRowData).formatting : null
      );

      returnValue = returnValue.concat(summedValues);
    });

    return returnValue;
  }, []);

  return {
    mapChartData,
    getChartData
  };
};

export default useChartConfig;
